export let counter = 0;
counter++;
interface PlatterDataCallback {
  (sequence: number, topX: number, topY: number, bottomX: number, bottomY: number, batteryVolt: number, batteryCur: number, proxMSB: number, prox2nd: number, proxLSB: number): void;
}
let dataCallback: PlatterDataCallback;

export function setDataCallback (callback: PlatterDataCallback) {
  dataCallback = callback;
}

export interface LED {
  red: boolean,
  green: boolean,
  blue: boolean,
}

export interface LEDMessage {
  leds: LED[],
  duration: number
}

export interface LEDMessageComponent {
  color: LED,
  locations: number[],
}

let device: HIDDevice;
export let displayCommandBuffer: LEDMessage;
let localLEDCommandBuffer: LEDMessage[] = [];
export let connected: boolean;
export let simulator: boolean;
let dataBuffer: number[];
const currentDataSequence = 0;
export function increment (): void {
  counter++;
}
let speedMode: number;
speedMode = 45;
export function changePlaySpeed (mode: number): void {
  speedMode = mode;
}

export async function connect () {
  try {
    const devices = await navigator.hid.requestDevice({
      filters: [
        {
          vendorId: 0x403,
          productId: 0x6030,
          usagePage: 0xFF00,
          usage: 0x1
        }
      ]
    });
    devices.forEach((device, i: number) => {
      console.log(`HID ${i}: ${device.productName}`, device);
    });
    if (devices.length === 0) {
      console.log("No device was selected.");
      simulator = true;
      connected = true;
      simulateDataReceived();
      return;
    }
    const uartDevices = devices.filter(device => device.collections[0].inputReports?.some(report => report.reportId === 240));
    device = uartDevices[0];
  } catch (error) {
    console.log("An error occurred.", error);
    if (window.navigator.userAgent.indexOf("CrOS") !== -1) {
      simulator = true;
      connected = true;
      simulateDataReceived();
      return;
    }
  }
  console.log(`HID: ${device.productName}`);

  await device.open();
  connected = true;
  await configureUART();
  device.addEventListener("inputreport", event => {
    const { data, device, reportId } = event;
    // console.log("hid input event: ", event);
    // Handle only the tlive platter events
    if (device.productId !== 0x6030) return;
    // Handle only UART Input Reports
    if (reportId < 0xF0 || reportId > 0xFE) return;

    //
    // if(csLessonIndex == 1) {
    //   try {
    //     eval(studentCode);
    //   }
    //   catch(error) {
    //     logToWindow("Student Function Error: ", error);
    //   }
    // }
    //
    // if(platterChartEnabled) {
    //   if(chartSensorDataIsTop) {
    //     processBottomSensorData(topY);
    //   }
    //   else {
    //     processBottomSensorData(bottomY);
    //   }
    // }
    // console.log("report id: ", reportId);
    updateDataBuffer([...new Uint8Array(data.buffer)]);
  });
}

export async function disconnect () {
  try {
    if (device) {
      await device.close();
      connected = false;
    } else {
      simulator = false;
      connected = false;
    }
  } catch (error) {
    console.log("An error occurred.", error);
  }
}

async function simulateDataReceived () {
  /* eslint-disable no-unmodified-loop-condition */
  while (connected && simulator) {
    const DATA_SEQUENCE_BYTE = counter++;
    const TOP_FS_DELTA_X = 20;
    const TOP_FS_DELTA_Y = -30;
    const BOT_FS_DELTA_X = 20;
    const BOT_FS_DELTA_Y = -speedMode;
    const PLATTER_BATT_VOLTAGE = 30;
    const PLATTER_BATT_CURRENT = 40;
    const PROX_SENSOR_MSB = 0;
    const PROX_SENSOR_2ND = 0;
    const PROX_SENSOR_LSB = 0;
    const data = [DATA_SEQUENCE_BYTE, TOP_FS_DELTA_X, TOP_FS_DELTA_Y, BOT_FS_DELTA_X, BOT_FS_DELTA_Y, PLATTER_BATT_VOLTAGE,
      PLATTER_BATT_CURRENT, PROX_SENSOR_MSB, PROX_SENSOR_2ND, PROX_SENSOR_LSB, 85];
    sendDataCallback(data);
    await sleep(50);
  }
  /* eslint-enable no-unmodified-loop-condition */
}
export function simulateScratch (dist: number): void {
  if (connected && simulator) {
    const DATA_SEQUENCE_BYTE = counter++;
    const TOP_FS_DELTA_X = 20;
    let TOP_FS_DELTA_Y = 128 / (Math.E * Math.log(dist));
    if (TOP_FS_DELTA_Y > 128) {
      TOP_FS_DELTA_Y = 128;
    } else if (TOP_FS_DELTA_Y < -128) {
      TOP_FS_DELTA_Y = -128;
    }
    const BOT_FS_DELTA_X = 20;
    const BOT_FS_DELTA_Y = -speedMode;
    const PLATTER_BATT_VOLTAGE = 30;
    const PLATTER_BATT_CURRENT = 40;
    const PROX_SENSOR_MSB = 0;
    const PROX_SENSOR_2ND = 0;
    const PROX_SENSOR_LSB = 0;
    const data = [DATA_SEQUENCE_BYTE, TOP_FS_DELTA_X, TOP_FS_DELTA_Y, BOT_FS_DELTA_X, BOT_FS_DELTA_Y, PLATTER_BATT_VOLTAGE,
      PLATTER_BATT_CURRENT, PROX_SENSOR_MSB, PROX_SENSOR_2ND, PROX_SENSOR_LSB, 85];
    sendDataCallback(data);
  }
}

function updateDataBuffer (data: number[]) {
  if (data.length === 0) return;
  const validDataLength = data[0];
  if (data.length < validDataLength + 1) return;

  if (dataBuffer === undefined) {
    dataBuffer = data.slice(1, validDataLength + 1);
  } else {
    dataBuffer.push(...data.slice(1, validDataLength + 1));
  }
  // console.log("dataBuffer: ", dataBuffer);
  if (dataBuffer.length >= 11 && dataBuffer[10] === 85) {
    sendDataCallback(dataBuffer.splice(0, 11));
  }
  // dataCallback(data.byteLength, data.buffer);
}

function sendDataCallback (data: number[]) {
  const DATA_SEQUENCE_BYTE = data[0];
  const TOP_FS_DELTA_X = data[1];
  const TOP_FS_DELTA_Y = data[2];
  const BOT_FS_DELTA_X = data[3];
  const BOT_FS_DELTA_Y = data[4];
  const PLATTER_BATT_VOLTAGE = data[5];
  const PLATTER_BATT_CURRENT = data[6];
  const PROX_SENSOR_MSB = data[7];
  const PROX_SENSOR_2ND = data[8];
  const PROX_SENSOR_LSB = data[9];
  // var CRC_BYTE = data[10];

  const topX = TOP_FS_DELTA_X > 128 ? TOP_FS_DELTA_X - 255 : TOP_FS_DELTA_X;
  const topY = (TOP_FS_DELTA_Y > 128 ? TOP_FS_DELTA_Y - 255 : TOP_FS_DELTA_Y) * -1;

  const bottomX = BOT_FS_DELTA_X > 128 ? BOT_FS_DELTA_X - 255 : BOT_FS_DELTA_X;
  const bottomY = (BOT_FS_DELTA_Y > 128 ? BOT_FS_DELTA_Y - 255 : BOT_FS_DELTA_Y) * -1;

  dataCallback(DATA_SEQUENCE_BYTE, topX, topY, bottomX, bottomY, PLATTER_BATT_VOLTAGE, PLATTER_BATT_CURRENT, PROX_SENSOR_MSB, PROX_SENSOR_2ND, PROX_SENSOR_LSB);
  //
  // var BOTTOM_SENSOR = bottomY, TOP_SENSOR = topY;
}

async function configureUART () {
  const configUARTBytes = Array(10);
  // configUARTBytes[0] = 0xA1;
  configUARTBytes[0] = 0x41;
  configUARTBytes[1] = 0x04;
  configUARTBytes[2] = 0x00;
  configUARTBytes[3] = 0xC2;
  configUARTBytes[4] = 0x01;
  configUARTBytes[5] = 0x00;

  configUARTBytes[6] = 0x08;
  configUARTBytes[7] = 0x00;
  configUARTBytes[8] = 0x00;
  configUARTBytes[9] = 0x00;
  console.log("config uart bytes: ", Uint8Array.from(configUARTBytes));
  try {
    await device.sendFeatureReport(0xA1, Uint8Array.from(configUARTBytes));
  } catch (error) {
    console.log("config uart error: ", error);
  }
}

function sleep (ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function setLEDColor (red: boolean, green: boolean, blue: boolean, ledLocation = 0) {
  console.log(`setLEDColor red: ${red}, green: ${green}, blue: ${blue}`);
  if (localLEDCommandBuffer.length === 1 && ledLocation > 0) {
    // modify color location in-place
    localLEDCommandBuffer[0].leds[ledLocation - 1] = { red, green, blue };
  } else {
    // otherwise, clear the buffer and start fresh
    localLEDCommandBuffer = [];
    const ledLocations = ledLocation === 0 ? [...Array(33).keys()] : [ledLocation];
    addLEDAnimationStep({ red, green, blue }, 255, ledLocations);
  }
}

export async function setLEDAnimation () {
  // hardcoded function for testing LED animation
  const ledArray1: LED[] = [];
  const ledArray2: LED[] = [];

  // alternate all red and all blue
  // for (let i = 0; i < 32; i++) {
  //   ledArray1.push({ id: i + 1, red: true, green: false, blue: false });
  //   ledArray2.push({ id: i + 1, red: false, green: false, blue: true });
  // }

  // alternate led locations, all red
  for (let i = 0; i < 32; i++) {
    if (i % 2 === 0) {
      ledArray1.push({ red: true, green: false, blue: false });
      ledArray2.push({ red: false, green: false, blue: false });
    } else {
      ledArray1.push({ red: false, green: false, blue: false });
      ledArray2.push({ red: true, green: false, blue: false });
    }
  }

  const ledCommandBytes1 = constructLEDMessageList({ leds: ledArray1, duration: 50 }, 0, true);
  const ledCommandBytes2 = constructLEDMessageList({ leds: ledArray2, duration: 50 }, 1, true);

  try {
    // await ledHardReset();
    await device.sendReport(0xF5, Uint8Array.from(ledCommandBytes1));
    await sleep(100);
    await device.sendReport(0xF5, Uint8Array.from(ledCommandBytes2));
    // await ledStart();
  } catch (error) {
    console.log("set LED color error: ", error);
  }
}

export async function addMultiColorLEDAnimationStep (components: LEDMessageComponent[], duration: number) {
  const ledArray: LED[] = new Array(32);
  ledArray.fill({ red: false, green: false, blue: false }); // initialize all LEDs to false
  for (const component of components) {
    for (let i = 0; i < 32; i++) {
      if (component.locations.includes(i + 1)) {
        ledArray[i] = { red: component.color.red, green: component.color.green, blue: component.color.blue };
      }
    }
  }
  localLEDCommandBuffer.push({ leds: ledArray, duration: duration });
}

export async function addLEDAnimationStep (color: LED, duration: number, ledLocations: number[] = [...Array(33).keys()]) {
  const ledArray: LED[] = [];
  for (let i = 0; i < 32; i++) {
    if (ledLocations.includes(i + 1)) {
      ledArray.push({ red: color.red, green: color.green, blue: color.blue });
    } else {
      ledArray.push({ red: false, green: false, blue: false });
    }
  }
  localLEDCommandBuffer.push({ leds: ledArray, duration: duration });
}

export async function executeLEDAnimationBuffer () {
  if (localLEDCommandBuffer.length > 0) {
    // await ledHardReset();
    // await sleep(100);
    // TODO: need to keep track of length of last led animation buffer. If this length is less, than we need to hard reset. Otherwise no? maybe?
  }
  if (simulator) {
    console.log("buffer", localLEDCommandBuffer);
    for (let i = 0; i < localLEDCommandBuffer.length; i++) {
      displayCommandBuffer = localLEDCommandBuffer[i];
      await sleep(localLEDCommandBuffer[i].duration);
    }
  } else {
    for (let i = 0; i < localLEDCommandBuffer.length; i++) {
      const ledMessage = localLEDCommandBuffer[i];
      const ledCommandBytes = constructLEDMessageList({ leds: ledMessage.leds, duration: ledMessage.duration }, i, true);
      await device.sendReport(0xF5, Uint8Array.from(ledCommandBytes));
      if (localLEDCommandBuffer.length > 1) {
        await sleep(100);
      }
    }
  }

  if (localLEDCommandBuffer.length > 0) {
    await sleep(100);
    await ledStart();
  }

  localLEDCommandBuffer = [];
}

export async function ledHardReset () {
  const ledCommandBytes = [0x33, 0x33, 0x01, 0x10, 2, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00];
  ledCommandBytes.push(getChecksum(ledCommandBytes));

  ledCommandBytes.unshift(0x15); // adding size header

  console.log("hard reset LED");
  console.log("computed led bytes: ", ledCommandBytes);
  if (simulator) {
    console.log("reset", localLEDCommandBuffer);
    return;
  }
  try {
    await device.sendReport(0xF5, Uint8Array.from(ledCommandBytes));
  } catch (error) {
    console.log("hard reset LED error: ", error);
  }
}

export async function ledStart () {
  const ledCommandBytes = [0x33, 0x33, 0x01, 0x10, 1, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00];
  ledCommandBytes.push(getChecksum(ledCommandBytes));

  ledCommandBytes.unshift(0x14); // adding size header

  console.log("start LED");
  console.log("computed led bytes: ", ledCommandBytes);
  if (simulator) {
    console.log("start", localLEDCommandBuffer);
    return;
  }

  try {
    await device.sendReport(0xF5, Uint8Array.from(ledCommandBytes));
    await sleep(100);
  } catch (error) {
    console.log("start LED error: ", error);
  }
}

function constructLEDMessageList (message: LEDMessage, ledAddress: number, last: boolean) {
  let ledCommandBytes = [0x33, 0x33, 0x01];

  // num bytes in message list including checksum. 15 bytes per led command message plus 1 byte checksum
  ledCommandBytes.push(15 + 1);

  // control byte, need to set last message indicator
  const controlByte = last ? 4 : 0;
  ledCommandBytes.push(controlByte);

  ledCommandBytes.push(ledAddress);
  ledCommandBytes.push(message.duration);

  ledCommandBytes = [...ledCommandBytes, ...constructLEDMessage(message.leds)];

  ledCommandBytes.push(getChecksum(ledCommandBytes));

  ledCommandBytes.unshift(ledCommandBytes.length); // adding size header

  console.log("computed led message list bytes: ", ledCommandBytes);

  return ledCommandBytes;
}

function constructLEDMessage (ledArray: LED[]) {
  const ledMessageBtyes = [];

  const redBits: string[] = [];
  const blueBits: string[] = [];
  const greenBits: string[] = [];

  let redTempBits = "";
  let blueTempBits = "";
  let greenTempBits = "";

  for (let i = 0; i < 32; i++) {
    const led = ledArray[i];
    redTempBits += (+led.red).toString();
    blueTempBits += (+led.blue).toString();
    greenTempBits += (+led.green).toString();

    if (redTempBits.length === 8) {
      redBits.push(redTempBits);
      blueBits.push(blueTempBits);
      greenBits.push(greenTempBits);

      redTempBits = "";
      blueTempBits = "";
      greenTempBits = "";
    }
  }

  for (let i = 0; i < redBits.length; i++) {
    ledMessageBtyes.push(parseInt(redBits[i], 2));
  }
  for (let i = 0; i < greenBits.length; i++) {
    ledMessageBtyes.push(parseInt(greenBits[i], 2));
  }
  for (let i = 0; i < blueBits.length; i++) {
    ledMessageBtyes.push(parseInt(blueBits[i], 2));
  }

  console.log("configuring LED");
  console.log("computed led message bytes: ", ledMessageBtyes);

  return ledMessageBtyes;
}

function getChecksum (payload: number[]) {
  let checksum = 0x00;
  for (let i = 0; i < payload.length; i++) {
    checksum ^= payload[i];
  }
  console.log("checksum: ", checksum);
  return checksum;
}
