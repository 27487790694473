
import { defineComponent } from "vue";
import { displayCommandBuffer } from "../platter-hid/platter";
import * as platter from "../platter-hid/platter";

export default defineComponent({
  name: "PlatterDisplay",
  props: {
    msg: String
  },
  data: function () {
    return {
      numRevolutions: 0,
      animationId: 0,
      lastTimestamp: 0,
      scratchdist: 0,
      scratching: false,
      lastScratchTimestamp: new Date(),
      rpmMode: 45,
      lastX: 0,
      lastY: 0,
      size: 256,
      nowTimestamp: 0
    };
  },
  created: function () {
    this.animationId = requestAnimationFrame(this.getNow);
  },
  mounted: function () {
    const el = document.getElementById("g1");
    var dAttribute = " ";
    if (el) {
      for (let i = 0; i < 32; i++) {
        const newpath = document.createElementNS("http://www.w3.org/2000/svg", "path");
        dAttribute = this.describeArc(256, 256, 240, i * 11.25, (i + 1) * 11.25 - 3);
        newpath.setAttributeNS(null, "d", dAttribute);
        newpath.setAttributeNS(null, "stroke", "#000000");
        newpath.setAttributeNS(null, "fill", "none");
        newpath.setAttributeNS(null, "stroke-width", "15");
        el.appendChild(newpath);
      }
    }
  },
  watch: {
    rpmMode: function playSpeed (newMode, oldMode) {
      platter.changePlaySpeed(newMode);
    },
    scratchdist: function scratch (newDist, oldDist) {
      platter.simulateScratch(newDist);
    }
  },
  computed: {
    musicLibraryRotateAngle: function () {
      return ((this.numRevolutions % 1) * 360).toFixed(0);
    }
  },
  methods: {
    getNow: function () {
      var timeElapsed = this.nowTimestamp - this.lastTimestamp;
      this.lastTimestamp = this.nowTimestamp;
      this.nowTimestamp = Date.now();
      cancelAnimationFrame(this.animationId);
      this.animationId = requestAnimationFrame(this.getNow);
      if (platter.connected && platter.simulator) {
        if (this.scratching) {
          this.numRevolutions = (this.scratchdist) / this.rpmMode;
        } else {
          this.numRevolutions = (this.nowTimestamp / 1000 / 60) * this.rpmMode;
        }
        const el = document.getElementById("g1");
        if (displayCommandBuffer && el) {
          for (let i = 0; i < 32; i++) {
            let colorStringRed = "00";
            let colorStringGreen = "00";
            let colorStringBlue = "00";

            if (displayCommandBuffer.leds[i].red) {
              colorStringRed = "ff";
            }
            if (displayCommandBuffer.leds[i].green) {
              colorStringGreen = "ff";
            }
            if (displayCommandBuffer.leds[i].blue) {
              colorStringBlue = "ff";
            }
            // console.log("led", i, "stroke", "#" + colorStringRed + colorStringGreen + colorStringBlue);
            el.children[i + 8].setAttributeNS(null, "stroke", "#" + colorStringRed + colorStringGreen + colorStringBlue);
          }
        }
      }
    },
    onMouseEnter: function (ev:PointerEvent) {
      this.lastX = ev.offsetX;
      this.lastY = ev.offsetY;
      this.scratching = true;
    },

    onMouseLeave: function (ev:PointerEvent) {
      console.log("leave", ev);
      this.lastX = 0;
      this.lastY = 0;
      this.scratching = false;
      this.scratchdist = 0;
    },

    onMouseMove: function (ev:PointerEvent) {
      var currentTime = new Date();
      const deltaX = ev.offsetX - this.lastX;
      const deltaY = ev.offsetY - this.lastY;

      var direction;
      let rotation = 0;
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (ev.offsetY > this.size / 2.0) {
          direction = ev.offsetX > this.lastX / 2.0 ? -1.0 : 1.0;
        } else {
          direction = ev.offsetX > this.lastX / 2.0 ? 1.0 : -1.0;
        }
        rotation = (deltaX / this.size) * 180.0 * direction;
        this.scratchdist += Math.abs(deltaX);
      } else {
        if (ev.offsetX > this.size / 2.0) {
          direction = ev.offsetY > this.lastY / 2.0 ? 1.0 : -1.0;
        } else {
          direction = ev.offsetY > this.lastY / 2.0 ? -1.0 : 1.0;
        }
        rotation = (deltaY / this.size) * 180.0 * direction;
        this.scratchdist += Math.abs(deltaY);
      }
      var revolutions = rotation / 360;

      this.scratchdist = Math.abs(revolutions / ((currentTime.valueOf() - this.lastScratchTimestamp.valueOf()) / 60000));
      // this.scratchdist = 0;
      this.lastScratchTimestamp = currentTime;
      this.lastX = ev.offsetX;
      this.lastY = ev.offsetY;
    },

    polarToCartesian: function (centerX: number, centerY: number, radius: number, angleInDegrees: number) {
      var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
      };
    },
    describeArc: function (x: number, y: number, radius: number, startAngle: number, endAngle: number): string {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);

      var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

      var d = [
        " ",
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
      ].join(" ");

      return d;
    }

  }

});
